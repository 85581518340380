<template>
  <div class="error-page">
    <div v-if="errorType" class="error-content">
      <el-image class="error-image"
        :style="errorType === 'frozen'?'margin-bottom: 0;width: 230px;height:230px':''" :src="errorImg">
      </el-image>
      <p style="color: #666;font-size: 14px" v-if="errorType === 'frozen'">请使用微信扫码</p>
      <p class="error-tip" v-if="errorTip">{{errorTip}}</p>
      <el-button style="margin-top: 80px" v-if="btnText" type="primary" @click="handleJump">{{btnText}}
      </el-button>
      <div class="reason" :style="errorType === 'frozen'?'margin-top: 29px;font-weight: bold':''"
        v-if="reason">{{reason}}</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      errorImg: "",
      errorTip: "",
      btnText: "",
      reason: "",

      type: '', // 必须声明为 data 属性，否则导致 computed 属性 watch 不到
      errorData: null
    };
  },
  computed: {
    error_data() {
      return this.errorData || this.$deCode(this.$route.params.errorData);
    },
    errorType() {
      return this.type || this.$route.params.type;
    },
  },
  watch: {
    errorType: {
      handler() {
        switch (this.errorType) {
          case "reviewed":
            this.errorImg = require("../assets/images/reviewed.png");
            this.errorTip =
              "您已提交项目申请，我们将在1～3个工作日内审核。审核通过后您可直接登录系统，如果审核不通过，将修改信息后再提交。";
            this.btnText = "";
            break;
          case "nopass":
            this.errorImg = require("../assets/images/no-pass.png");
            this.errorTip = "您的项目未通过审核，请修改信息后再提交。";
            this.btnText = "去修改信息";
            break;
          case "overdue":
            this.errorImg = require("../assets/images/overdue.png");
            this.errorTip = "您所购买的项目已到期，请续费。";
            this.btnText = "去续费";
            break;
          case "disable":
            this.errorImg = require("../assets/images/is-disable.png");
            this.errorTip = `您的${this.error_data.name}项目处于禁用状态`;
            this.reason = `原因是：${this.error_data.reason}。如不是这种情况，请和客服(24小时客服电话：020-28185098-666)联系。`;
            break;
          case "frozen":
            this.errorImg = this.fixImageUrl(this.error_data.qr_code, { h: 230 });
            this.reason = this.error_data.reason;
            break;
          default:
            // 刷新页面后，$route.params 参数就没再保留下来了
            // 不可再用 replace 之类的跳转路由了，因为又会触发路由守卫但 GET_WEB_CONFIG 是读 vuex 值而不是重新获取
            this.$store.dispatch('GET_WEB_CONFIG').then(() => {
              this.$router.replace({ name: "Home" });
            }).catch(err => {
              this.type = err.params.type
              this.errorData = this.$deCode(err.params.errorData)
            })
        }
      },
      immediate: true
    }
  },
  methods: {
    handleJump() {
      if (this.errorType === "reviewed") {
        this.$router.replace({ name: "Login" });
      }
      if (
        this.errorType === "nopass" ||
        this.errorType === "overdue"
      ) {
        window.open(
          window.serverConfig.VUE_APP_OTHER_BASEURL["user-admin"] +
            "?redirect=Login",
          "_blank"
        );
      }
    },
  },
  created() {
  },
};
</script>
<style lang="scss" scoped>
.error-page {
  height: 100vh;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  .error-content {
    text-align: center;
    .error-image {
      width: 320px;
      height: 296px;
      margin-bottom: 54px;
    }
    .error-tip {
      margin: auto;
      width: 889px;
      font-size: 24px;
      font-weight: 400;
      color: #333333;
      line-height: 33px;
    }
    .reason {
      margin: auto;
      width: 480px;
      font-size: 16px;
      color: #606266;
      line-height: 24px;
      margin-top: 40px;
    }
  }
}
</style>
